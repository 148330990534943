import { render, staticRenderFns } from "./chapterQuestionDetail.vue?vue&type=template&id=74115b7d&scoped=true&"
import script from "./chapterQuestionDetail.vue?vue&type=script&lang=js&"
export * from "./chapterQuestionDetail.vue?vue&type=script&lang=js&"
import style0 from "./chapterQuestionDetail.vue?vue&type=style&index=0&id=74115b7d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74115b7d",
  null
  
)

export default component.exports